<template>
  <footer
    class="bg-gray-800"
    aria-labelledby="footerHeading"
  >
    <h2
      id="footerHeading"
      class="sr-only"
    >
      Footer
    </h2>
    <div class="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold uppercase tracking-wider text-gray-400">
                Pilot Car Solutions
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <nuxt-link
                    to="/pilot-cars"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Pilot Car Drivers / Escort Cars
                  </nuxt-link>
                </li>

                <li>
                  <nuxt-link
                    to="/post-a-load"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Post a Load
                  </nuxt-link>
                </li>

                <li>
                  <nuxt-link
                    to="/load-board"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Pilot Car Load Board
                  </nuxt-link>
                </li>

                <li>
                  <nuxt-link
                    to="/app"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Pilot Car App
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold uppercase tracking-wider text-gray-400">
                Pilot Car Products
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <nuxt-link
                    to="/pricing"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Pricing
                  </nuxt-link>
                </li>

                <li>
                  <nuxt-link
                    to="/why-choose-us"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Why Choose Us?
                  </nuxt-link>
                </li>

                <li>
                  <a
                    href="http://help.pilotcarloads.com/en/collections/2717399-what-s-new"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    What's New
                  </a>
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/pilot-car-loads/id1551499871"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    iPhone & iPad App
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=focusstate.pilotcarloads"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Android App
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold uppercase tracking-wider text-gray-400">
                Company
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <nuxt-link
                    to="/blog"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Blog
                  </nuxt-link>
                </li>

                <li>
                  <nuxt-link
                    to="/contact"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Contact
                  </nuxt-link>
                </li>

                <li>
                  <a
                    href="http://help.pilotcarloads.com/en/collections/2714494-faqs"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    FAQs / Help
                  </a>
                </li>
                <li>
                  <nuxt-link
                    to="/login"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Login
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    to="/register"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Register
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold uppercase tracking-wider text-gray-400">
                Legal
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a
                    href="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Terms
                  </a>
                </li>

                <li>
                  <a
                    href="/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Privacy
                  </a>
                </li>

                <li>
                  <a
                    href="/cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Cookies
                  </a>
                </li>

                <li>
                  <a
                    rel="noopener noreferrer"
                    href="https://app.termly.io/notify/4d064848-02fb-4f10-b37b-9796cfca5f49"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Do Not Sell My Info
                  </a>
                </li>

                <li>
                  <a
                    href="/accessibility "
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-base text-gray-300 hover:text-white"
                  >
                    Accessibility
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-8 xl:mt-0">
          <h3 class="text-sm font-semibold uppercase tracking-wider text-gray-400">
            About Pilot Car Loads
          </h3>
          <p class="mt-4 text-sm text-gray-300">
            Pilot Car Loads is a new and modern load board serving the oversize escort industry. Pilot car drivers and escorts can find jobs faster and more efficiently than ever before. Pilot Car Brokers and Oversize Companies can post for free. Pilot car drivers can start a free trial today to gain access to the load board and start receiving alerts specific to you.
          </p>
          <p class="mt-4 text-xs text-gray-300">
            *Free trial is only applicable to new accounts.
          </p>
        </div>
      </div>

      <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
        <div class="flex space-x-6 md:order-2">
          <a
            href="https://facebook.com/pilotcarloadboard"
            class="flex text-gray-400 hover:text-gray-300"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span class="sr-only">Facebook</span>
            <svg
              class="mr-2 size-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
            Follow us on Facebook
          </a>
        </div>
        <p class="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
          &copy; {{ new Date().getFullYear() }} Pilot Car Loads. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
