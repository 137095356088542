<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';

const closeButtonRef = ref(null);

onClickOutside(closeButtonRef, () => closeMenu());

const mobileOpen = ref(false);
const navLinks = [
  {
    name: 'Pilot Car Drivers',
    to: '/pilot-cars',
  },
  {
    name: 'Post a Load',
    to: '/post-a-load',
  },
  {
    name: 'Load Board',
    to: '/load-board',
  },
  {
    name: 'Pricing',
    to: '/pricing',
  },
];

const isIE = computed(() => {
  if (!process.browser) {
    return false;
  }

  const ua = window.navigator?.userAgent; // Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); // IE 11

  return (msie > 0 || trident > 0);
});

const route = useRoute();

watch(route, () => {
  closeMobileMenu();
});

function toggleMobileMenu() {
  mobileOpen.value = !mobileOpen.value;
}

function closeMobileMenu() {
  mobileOpen.value = false;
}

function closeMenu() {
  if (mobileOpen.value) {
    mobileOpen.value = false;
  }
}
</script>

<template>
  <div class="relative bg-white">
    <dashboard-alerts-alert
      v-if="isIE"
      alert-type="error"
    >
      This site is not compatible with IE. Please switch your browser to continue.
    </dashboard-alerts-alert>
    <div class="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
      <div>
        <nuxt-link
          to="/"
          class="flex"
        >
          <img
            src="~assets/img/logo.svg"
            class="block h-14"
            alt="Pilot Car Loads Logo"
          >
        </nuxt-link>
      </div>
      <div class="-my-2 -mr-2 md:hidden">
        <button
          class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
          @click="toggleMobileMenu"
        >
          <span class="pr-2">Menu</span>
          <svg
            class="size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <div class="hidden md:flex md:flex-1 md:items-center md:justify-between md:space-x-12">
        <nav class="flex space-x-10">
          <nuxt-link
            v-for="(link, idx) in navLinks"
            :key="idx"
            :to="link.to"
            class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:text-gray-900 focus:outline-none"
          >
            {{ link.name }}
          </nuxt-link>
        </nav>
        <div class="flex items-center space-x-8">
          <nuxt-link
            to="/login"
            class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:text-gray-900 focus:outline-none"
          >
            Log in
          </nuxt-link>
          <span class="inline-flex rounded-md shadow-sm">
            <nuxt-link
              to="/register"
              class="inline-flex items-center justify-center rounded-md border border-transparent bg-teal-700 px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-teal-600 focus:border-teal-800 focus:outline-none active:bg-teal-800"
            >
              Sign up
            </nuxt-link>
          </span>
        </div>
      </div>

      <div
        v-if="mobileOpen"
        ref="closeButtonRef"
        class="absolute inset-x-0 top-0 z-20 origin-top-right p-2 transition md:hidden"
      >
        <div class="rounded-lg shadow-lg">
          <div class="shadow-xs divide-y-2 divide-gray-50 rounded-lg bg-white">
            <div class="space-y-6 px-5 pb-6 pt-5 sm:space-y-8 sm:pb-8">
              <div class="flex items-center justify-between">
                <div>
                  <nuxt-link to="/">
                    <img
                      src="~assets/img/logo.svg"
                      class="block h-12"
                      alt="Pilot Car Loads Logo"
                    >
                  </nuxt-link>
                </div>
                <div class="-mr-2">
                  <button
                    type="button"
                    class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                    @click="toggleMobileMenu"
                  >
                    <!-- Heroicon name: x -->
                    <svg
                      class="size-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="space-y-6 px-5 py-6">
              <div class="grid grid-cols-2 gap-4">
                <nuxt-link
                  v-for="(link, idx) in navLinks"
                  :key="idx"
                  class="cursor-pointer rounded-md text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                  :to="link.to"
                >
                  {{ link.name }}
                </nuxt-link>
              </div>
              <div class="space-y-6">
                <span class="flex w-full rounded-md shadow-sm">
                  <nuxt-link
                    to="/register"
                    class="flex w-full items-center justify-center rounded-md border border-transparent bg-teal-700 px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-teal-600 focus:border-teal-800 focus:outline-none active:bg-teal-800"
                  >
                    Sign up
                  </nuxt-link>
                </span>
                <p class="text-center text-base font-medium leading-6 text-gray-500">
                  Existing customer?
                  <nuxt-link
                    to="/login"
                    class="text-teal-700 transition duration-150 ease-in-out hover:text-teal-500"
                  >
                    Log in
                  </nuxt-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
